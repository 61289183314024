import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetCommonBackgroundQuery } from "../features/api/publicApiSlice";
import { keyframes } from '@emotion/react';

import {
  useTheme,
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  FormControl,
  Container,
  Grid,
  Divider,
  List,
  ListItem,
  Stepper, Step, StepLabel,
} from "@mui/material";
import { Close, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import FormTextField from "../components/form/FormTextField";
import FormPhoneNumber from "../components/form/FormPhoneNumber";
import FormDatePicker from "../components/form/FormDatePicker";
import Image from "../components/common/Image";
import {
  useLazyGetOtpQuery,
  useRegisterAccountMutation,
} from "../features/api/publicApiSlice";
import { setAlert } from "../features/slices/alertSlice";
import { setCredentials } from "../features/slices/authSlice";
import { selectCurrentToken } from "../features/slices/authSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ImageCarousel from "../components/common/ImageCarousel";
import { apiSlice } from "../features/api/apiSlice";
import * as Constant from "../features/constant";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGetContactUsQuery } from "../features/api/publicApiSlice";
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';

// pending adding request mobile otp logic
const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const [detailValue, setDetailValue] = useState({ onThisPage: true });
  const [profileValue, setProfileValue] = useState({});
  const token = useSelector(selectCurrentToken);
  const lang = localStorage.getItem("language");

  const bonusSliderImage_en = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_en.png",
  ];

  const bonusSliderImage_bd = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_bd.png",
  ];

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });


  const registerDetailSchema = yup.object().shape({
    username: yup
      .string()
      .required(t("validation.username"))
      .test(
        "len",
        "Username must be between 8 to 20 characters.",
        (val) => val?.length >= 8 && val?.length <= 20
      ),
    password: yup
      .string()
      .required(t("validation.password"))
      .test(
        "len",
        t("validation.passwordlength"),
        (val) => val?.length >= 8 && val?.length <= 20
      ),
    confirmPassword: yup
      .string()
      .required(t("validation.confirmpassword"))
      .oneOf([yup.ref("password"), null], t("validation.passwordnotmatch")),
  });

  const registerProfileSchema = yup.object().shape({
    fullname: yup.string().required(t("validation.fullname")),
    email: yup
      .string()
      .email(t("validation.invalidemail"))
      .required(t("validation.emailisrequired")),
    mobileNumber: yup.string().required(t("validation.mobilenum")).test(
      "len",
      t("validation.mobilenumlength"),
      (val) => val?.length >= 15 && val?.length <= 20
    ),
    otp: yup.string().when("showOtp", {
      is: () => {
        /*if (process.env.REACT_APP_OTP_FIELD === "true") {
             return true;
          }*/
        if (commonBackground?.result?.otpFlag === "Y") {
          return true;
        }
        return false;
      }, // When requireOTP is true, OTP is required.
      then: yup.string().required(t("validation.otp")),
      otherwise: yup.string(), // OTP is not required when requireOTP is false.
    }),
  });

  const [register, { data, isLoading, isError, isSuccess, error, reset }] =
    useRegisterAccountMutation();

  const [
    getOtp,
    {
      data: otp,
      isFetching: isOtpFetching,
      isLoading: isOtpLoading,
      isError: isOtpError,
      isSuccess: isOtpSuccess,
      error: otpError,
    },
  ] = useLazyGetOtpQuery();

  const { control: detailControl, handleSubmit: handleSubmitDetail } = useForm({
    resolver: yupResolver(registerDetailSchema),
  });

  const {
    control: profileControl,
    handleSubmit: handleSubmitProfile,
    getValues,
  } = useForm({
    resolver: yupResolver(registerProfileSchema),
  });

  const submitDetail = async (data) => {
    setDetailValue({ onThisPage: false, ...data });
    handleNext();
  };

  const submitProfile = async (data) => {
    setProfileValue({ ...data });
    let formData = {
      ...data,
      username: detailValue?.username,
      password: detailValue?.password,
      dateOfBirth: data?.date,
      mobileNumber: data?.mobileNumber.replace(/[^A-Z0-9]+/gi, ""),
      referralCode: data?.referralCode,
      otp: data?.otp,
      role: "AFFILIATE"
    };

    window.fbq('trackCustom', 'CompleteRegister');

    await register(formData).unwrap();
    handleNext();
    localStorage.clear();
  };

  if (!isLoading && isSuccess) {
    window.registerListener = window.registerListener || window;

    dispatch(
      setAlert({
        isOpen: true,
        message: t("header.registersuccessmessage"),
        variant: "success",
      })
    );
    dispatch(setCredentials({ ...data?.result }));
    //navigate(Constant.getPath("/"));
    reset();
    window.addEventListener('load', function () {
      // Ensure window.fbq and window.bge are available before calling them
      if (typeof window.fbq === 'function') {
        window.fbq('track', 'CompleteRegistration');
      } else {
        console.error('window.fbq is not available');
      }

      if (typeof window.bge === 'function') {
        window.bge('event', 'ec_register', { configId: '904500226561349376' });
      } else {
        console.error('window.bge is not available');
      }
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const registerCompleteMessage = { event: 'af_complete_registration' };
    window.registerListener.postMessage(JSON.stringify(registerCompleteMessage));
  }

  if (!isLoading && isError) {
    dispatch(
      setAlert({
        isOpen: true,
        message: error?.data?.message,
        variant: "error",
      })
    );
    reset();
  }

  useEffect(() => {
    if (!isOtpLoading && isOtpSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: "Otp is sent",
          variant: "success",
        })
      );
    }

    if (!isOtpLoading && isOtpError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: otpError?.data?.message,
          variant: "error",
        })
      );
    }
  }, [isOtpLoading, isOtpSuccess, isOtpError]);

  const [telegramURL, setTelegramURL] = useState('');
  const [emailURL, setEmailURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [whatsappURL, setWhatsappURL] = useState('');
  const [instaURL, setInstaURL] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    data: contactUs,
    isSuccess: isGetContactUsSuccess,
  } = useGetContactUsQuery();

  useEffect(() => {

    if (contactUs && contactUs.data) {

      const email = contactUs.data[0]?.contactDetails.email.url;
      const cleanEmail = email.replace("mailto:", "");
      const whatsapp = contactUs.data[0]?.contactDetails.whatsapp.url;
      setTelegramURL(contactUs.data[0]?.contactDetails.telegram.url);
      setEmailURL(cleanEmail);
      setFacebookURL(contactUs.data[0]?.contactDetails.facebook.url);
      setWhatsappURL(contactUs.data[0]?.contactDetails.whatsapp.url);
      setInstaURL(contactUs.data[0]?.contactDetails.instagram.url);
      setPhoneNumber(whatsapp.split("/").pop());
    }
  }, [contactUs]);


  const renderDetailSection = () => (
    <form onSubmit={handleSubmitDetail(submitDetail)}>
      <FormTextField
        label={t("mobileloginregister.username")}
        control={detailControl}
        defaultValue={detailValue?.username}
        controllerName="username"
        size="small"
      />

      <FormTextField
        label={t("mobileloginregister.password")}
        control={detailControl}
        defaultValue={detailValue?.password}
        controllerName="password"
        type="password"
        size="small"
      />

      <FormTextField
        label={t("mobileloginregister.reenterpassword")}
        control={detailControl}
        value={detailValue?.confirmPassword}
        controllerName="confirmPassword"
        type="password"
        size="small"
      />

      <Box display="flex" justifyContent="center" mt="30px">
        <Button variant="contained" type="submit">
          {t("mobileloginregister.next")}
        </Button>
      </Box>
    </form>
  );

  const renderProfileSection = () => (
    <form onSubmit={handleSubmitProfile(submitProfile)}>
      <Box display="flex" flexDirection="column">
        <FormControl>
          <FormTextField
            label={t("mobileloginregister.fullname")}
            defaultValue={profileValue?.fullname || ""}
            control={profileControl}
            controllerName="fullname"
            size="small"
            note={t("mobileloginregister.fullnamenote")}
          />
          <FormTextField
            label={t("mobileloginregister.email")}
            defaultValue={profileValue?.email || ""}
            control={profileControl}
            controllerName="email"
            type="email"
            size="small"
          />
          <FormDatePicker
            control={profileControl}
            label={t("mobileloginregister.dob")}
            value="1990-01-01"
            time={false}
            size="small"
          />
          <FormPhoneNumber
            label={t("mobileloginregister.mobilenumber")}
            defaultValue={profileValue?.phoneNumber}
            control={profileControl}
            controllerName="mobileNumber"
            size="small"
          />
          {commonBackground?.result?.otpFlag === "Y" && (
            <>
              <Box alignSelf="center">
                <Button
                  sx={{
                    position: "absolute",
                    right: 0,
                    marginTop: "17px",
                    marginRight: "1px",
                    zIndex: 10,
                    paddingLeft: "10px",
                    paddingRight: "10px"
                  }}
                  variant="contained"
                  onClick={() => {
                    const mobileNumber = getValues().mobileNumber?.replace(
                      /[^A-Z0-9]+/gi,
                      ""
                    );
                    if (mobileNumber) {
                      getOtp(mobileNumber);
                    }
                  }}
                >
                  Get OTP
                </Button>
              </Box>
              <FormTextField
                label="Enter otp"
                defaultValue={profileValue?.otp || ""}
                control={profileControl}
                controllerName="otp"
                type="text"
                size="small"
              />
            </>
          )}
          <FormTextField
            label={t("mobileloginregister.refcode")}
            defaultValue={localStorage.getItem("refCode") || ""}
            control={profileControl}
            controllerName="referralCode"
            type="text"
            size="small"
            disabled={!localStorage.getItem("refCode") ? false : true}
          />
        </FormControl>
      </Box>
      <Grid container display="flex">
        <Grid Item xs={6}>
          <Box display="flex" flexDirection="row" alignItems="center" mt="10px" onClick={() => {
            setDetailValue({ onThisPage: true });
            handleBack();
          }
          }>
            <IconButton
              edge="start"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography py="5px" color="white">
              {" "}
              {t("mobileloginregister.back")}
            </Typography>
          </Box>
        </Grid>

        <Grid Item xs={6}>
          <Box display="flex" justifyContent="flex-end" mt="30px" paddingBottom="40px">
            <Button variant="contained" type="submit">
              {t("mobileloginregister.register")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );

  const bounce = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

  const renderReviewMessage = () => (
    <Box textAlign="center">
      <CheckCircleIcon
        sx={{
          fontSize: 60,
          color: 'success.main',
          animation: `${bounce} 1s ease infinite`, // Apply the animation
        }}
      />
      <Typography variant="h6" color="white" mt={2}>
        {t("mobileloginregister.appreviewtitle")}
      </Typography>
      <Typography variant="h10" color="white" mt={2}>
        {t("mobileloginregister.appreviewmsg")}
      </Typography>

      <Typography variant="body1" color="white" mt={4}>
        {t("mobileloginregister.contactdescription")}
      </Typography>
      <Box display="flex" justifyContent="center" mt="30px" gap={2}>
        {/* Telegram Icon Button */}
        <IconButton
          aria-label="Telegram"
          onClick={() => window.open(telegramURL, "_blank")}
          sx={{
            color: 'white',
            fontSize: 40, // Increase size
          }}
        >
          <TelegramIcon sx={{ fontSize: 40 }} /> {/* Increase icon size */}
        </IconButton>

        {/* Email Icon Button */}
        <IconButton
          aria-label="Email"
          onClick={() => window.open(`mailto:${emailURL}`, "_blank")}
          sx={{
            color: 'white',
            fontSize: 40, // Increase size
          }}
        >
          <EmailIcon sx={{ fontSize: 40 }} /> {/* Increase icon size */}
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center" mt="30px">
        <Button variant="contained" type="submit" onClick={() => { navigate(Constant.getPath("/")); }}>
          {t("mobileloginregister.returnhome")}
        </Button>
      </Box>
    </Box>
  );

  const [step, setStep] = useState(1);

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <Box backgroundColor="black" height={mobileView && "120vh"} >
      {mobileView ? (
        <>
          {/* <Box p={2} backgroundColor="black">
            <Grid container justifyContent="space-between">
              <Image
                width="178px"
                src={process.env.REACT_APP_LOGO}
                height="50px"
                onClick={null}
              />
              <IconButton
                onClick={() => {
                  navigate("/");
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Box> */}
          <Container>
            <Box mt={12}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 3,
                }}
              >
                <Box
                  p={2}
                  flexGrow={1}
                  sx={{ cursor: "pointer", position: "relative" }}
                  onClick={() => navigate(Constant.getPath("/login"))}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={800}
                    align="center"
                    color="white"
                  >
                    {t("mobileloginregister.login")}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box
                  p={2}
                  flexGrow={1}
                  sx={{ cursor: "pointer", position: "relative" }}
                >
                  <Typography
                    color={theme.palette.secondary.main}
                    fontSize={14}
                    fontWeight={800}
                    align="center"
                  >
                    {t("mobileloginregister.signup")}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 5,
                      left: "50%",
                      transform: "translateX(-50%)",
                      borderBottom: "2px solid",
                      width: "20%",
                      borderColor: theme.palette.secondary.main,
                    }}
                  />
                </Box>
              </Box>
              <Box>
                {/* <ImageCarousel
                  banners={(lang === 'en'|| lang === null) ? bonusSliderImage_en : bonusSliderImage_bd}
                  navigation={false}
                  spaceBetween={0}
                /> */}
                <Image
                  width="80%"
                  sx={{ justifySelf: "center" }}
                  src={process.env.REACT_APP_AFFILIATE_LOGO}
                  onClick={null}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ backgroundColor: theme.palette.background.paper }}
                justifyContent="center"
                pb={3}
                px={5}
              >
                {/* <Box>
                  {detailValue.onThisPage
                    ? renderDetailSection()
                    : renderProfileSection()}
                </Box> */}
                <Box sx={{ mt: 4 }}>
                  <Stepper activeStep={step - 1} sx={{ mb: 4 }}>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step completed={step === 3}>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>

                  <Box>
                    {step === 1 && renderDetailSection()}
                    {step === 2 && renderProfileSection()}
                    {step === 3 && renderReviewMessage()}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </>
      ) : (
        <Container>
          <Box px={tabletView ? 0 : 20} py={5}>

            <Typography
              fontWeight={800}
              fontSize={14}
              color={theme.palette.primary.main}
              mb={2}
            >
              {t("mobileloginregister.registration")}
            </Typography>
            <Box>
              {/* <ImageCarousel
                  banners={(lang === 'en'|| lang === null) ? bonusSliderImage_en : bonusSliderImage_bd}
                  navigation={false}
                  spaceBetween={0}
                /> */}
              <Image
                width="60%"
                sx={{ justifySelf: "center" }}
                src={process.env.REACT_APP_AFFILIATE_LOGO}
                onClick={null}
              />
            </Box>
            <Box
              pr="24px"
              py="24px"
              sx={{ backgroundColor: theme.palette.background.paper }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  flexGrow: 1,
                  px: 3,
                }}
              >
                <Box sx={{ mt: 4 }}>
                  <Stepper activeStep={step - 1} sx={{ mb: 4 }}>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step>
                      <StepLabel></StepLabel>
                    </Step>
                    <Step completed={step === 3}>
                      <StepLabel></StepLabel>
                    </Step>
                  </Stepper>

                  <Box>
                    {step === 1 && renderDetailSection()}
                    {step === 2 && renderProfileSection()}
                    {step === 3 && renderReviewMessage()}
                  </Box>
                </Box>
                {/* <Box pb={3}>
                  <Image width="250px" src={process.env.REACT_APP_LOGO} />
                </Box>
                {detailValue.onThisPage
                  ? renderDetailSection()
                  : renderProfileSection()} */}
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default Register;
