import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Constant from "../features/constant";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  List,
  ListItem,
  Button,
  Card,
  CardContent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../components/Affiliate/style.css"
import { testImg } from "../icons/header/affTest.jpg";
import Image from "../components/common/Image";
import { ArrowRight } from "@mui/icons-material";
import { selectCurrentToken } from "../features/slices/authSlice";



const AffiliateHome = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const lang = localStorage.getItem("language");
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();

  // const {
  //   data: homePage,
  // } = useGetHomePageQuery({ prefetch: true });

  const StepsToEarn = () => {
    return (
      <Box
        pt={6}
        pb={6}
        pl={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        pr={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        sx={{
          backgroundImage: `url(${require('../icons/header/backgroundslots.png')})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="title">
          <span>  {t("homepage.stepstoearn")}</span>
        </div>

        <Grid container spacing={7} pt={3} display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className="step"
              backgroundColor="transparent"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              alignItems="center">
              <Image src={require('../icons/header/signupprofile.png')} alignItems="center" sx={{ margin: "0 auto" }}></Image>
              <Typography variant="h1" color={"white"} paddingTop={"10px"}>{t("homepage.signup")}</Typography>
              <Typography color={"white"} paddingTop={"5px"}>{t("homepage.signuptext")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className="step"
              backgroundColor="transparent"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              alignItems="center">
              <Image src={require('../icons/header/verifiedtick.png')} alignItems="center" sx={{ margin: "0 auto" }}></Image>
              <Typography variant="h1" color={"white"} paddingTop={"10px"}>{t("homepage.getapproved")}</Typography>
              <Typography color={"white"} paddingTop={"5px"}>{t("homepage.getapprovedtext")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className="step"
              backgroundColor="transparent"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              alignItems="center">
              <Image src={require('../icons/header/moneysign.png')} alignItems="center" sx={{ margin: "0 auto" }}></Image>
              <Typography variant="h1" color={"white"} paddingTop={"10px"}>{t("homepage.promoteandearn")}</Typography>
              <Typography color={"white"} paddingTop={"5px"}>{t("homepage.promoteandearntext")}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            onClick={(() => {
              if (token) {
                navigate(Constant.getPath("/affiliateoverview"));
              } else {
                navigate(Constant.getPath("/register"));
              }
            })}
            sx={mobileView ? { alignSelf: "center", backgroundColor: "red", borderRadius: "50px", padding: "10px 50px", color: "black", fontWeight: "bolder", marginTop: "65px", width: "100%" }
              : { alignSelf: "center", backgroundColor: "red", borderRadius: "50px", padding: "10px 50px", color: "black", fontWeight: "bolder", marginTop: "65px" }}
          >{t("homepage.startearning")}
          </Button>
        </Box>

      </Box>
    );
  };

  const AffiliateAd = () => {
    return (
      <Box
        pt={6}
        pb={6}
        pl={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        pr={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        style={{
          display: "flex",
          justifycContent: "space-around",
          alignItems: "center"
        }}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            md={4}
            lg={6}
            style={{ color: 'white' }}
            order={{ xs: 3, sm: 3, md: 1, lg: 1 }}>
            <Typography>
              {t("homepage.introductiontext")}
            </Typography>
            <List>
              <ListItem> <ArrowRight /> <Typography>   {t("homepage.freetojoin")}</Typography></ListItem>
              <ListItem> <ArrowRight /> <Typography>   {t("homepage.highcommission")}</Typography></ListItem>
              <ListItem> <ArrowRight /> <Typography>   {t("homepage.easysignup")}</Typography></ListItem>
              <ListItem> <ArrowRight /> <Typography>   {t("homepage.readymade")}</Typography></ListItem>
              <ListItem> <ArrowRight /> <Typography>   {t("homepage.wellknown")}</Typography></ListItem>
            </List>
            {/* <Button
              sx={mobileView ? { backgroundColor: "red", borderRadius: "50px", padding: "10px 50px", color: "black", fontWeight: "bolder", marginTop: "20px", width: "100%" }
                : { backgroundColor: "red", borderRadius: "50px", padding: "10px 50px", color: "black", fontWeight: "bolder", marginTop: "20px" }}
            >START EARNING
            </Button> */}
          </Grid>

          <Grid
            lg={1}
            md={1}
            order={{ lg: 2, xl: 2 }}>
            <Box />
          </Grid>

          <Grid item
            xs={12}
            sm={12}
            md={7}
            lg={5}
            justifyContent="center"
            alignContent="center"
            order={{ xs: 1, sm: 1, md: 3, lg: 3 }} >
            <Image src={require('../icons/header/image1.png')} width={"100%"} maxHeight={"400px"} maxWidth={"400px"} alt="Affiliate Promotion" />
          </Grid>
        </Grid>
      </Box>
    );
  }

  const BestPartner = () => {
    return (
      <Box
        pt={6}
        pb={6}
        pl={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        pr={{ xs: 3, sm: 8, lg: 15, xl: 25 }}>

        <Box >
          <Typography sx={{ color: "white", fontSize: "28px", fontWeight: "bold", textAlign: "center", marginBottom: "40px" }}>
            {t("homepage.bestpartner")}
          </Typography>
        </Box>

        <Grid container display={"flex"} spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <img src={require('../icons/header/commissioncash.png')} alt="High Commission" style={{ width: '50%', marginBottom: '16px' }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white', paddingTop: '10px' }}>
                  {t("homepage.highcommission2")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.highcommissiontext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <img src={require('../icons/header/dashboard.png')} alt="Partner Dashboard" style={{ width: '60%', marginBottom: '16px' }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.partnerdashboard")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.partnerdashboardtext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <img src={require('../icons/header/managersupport.png')} alt="Dedicated Affiliate Manager" style={{ width: '40%', marginBottom: '16px' }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.affiliatemanager")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.affiliatemanagertext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={5.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <img src={require('../icons/header/marketingtool.png')} alt="Promotional Materials Involved" style={{ width: '60%', marginBottom: '16px' }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.promotionalmaterial")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.promotionalmaterialtext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
    );
  }

  const OurProduct = () => {
    return (
      <Box
        pt={6}
        pb={6}
        pl={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        pr={{ xs: 3, sm: 8, lg: 15, xl: 25 }}
        sx={{
          backgroundImage: `url(${require('../icons/header/backgroundslots2.png')})`,
          backgroundSize: "contain",
        }}>

        <Box>
          <Typography sx={{ color: "white", fontSize: "28px", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
            {t("homepage.ourproducts")}
          </Typography>

          <Typography sx={{ color: "white", textAlign: "center" }}>
            {t("homepage.ourproductstext")}
          </Typography>
        </Box>

        <Grid spacing={4} pt={{ xs: 6, sm: 11 }} container display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/livecasino.png')} alt="Live Casino" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.livecasino")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.livecasinotext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/sportsbook.png')} alt="Sportbook" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.sportsbook")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.sportsbooktext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/slotgames.png')} alt="Slot Game" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.slotgames")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.slotgamestext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/fishgames.png')} alt="Fish Game" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.fishgames")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.fishgamestext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/esports.png')} alt="Esport" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.esports")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.esportstext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3.5}>
            <Card elevation={3} sx={{ backgroundColor: 'black', border: '2px solid grey', }} style={{ width: '100%', height: '100%', padding: '20px', textAlign: 'center', borderRadius: '15px' }}>
              <Image src={require('../icons/header/cardgames.png')} alt="Card Game" sx={{ width: '100%', maxWidth: '125px', marginBottom: '16px', margin: "0 auto" }} />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontWeight: 'bold', color: 'white' }}>
                  {t("homepage.cardgames")}
                </Typography>
                <Typography variant="body2" color="white">
                  {t("homepage.cardgamestext")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const StartEarning = () => {
    return (
      <Box width={"100%"} height={550} sx={{/*backgroundImage: `url(${homePage?.result?.banners[0]})`,*/ backgroundSize: "100% 100%", backgroundPosition: "center", backgroundRepeat: "no-repeat", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "0 35px" }}>
        <Typography variant="h3" sx={{ color: "white", textAlign: "center" }}>
          {t("homepage.startearningincome")}
        </Typography>

        <Button
          onClick={(() => {
            if (token) {
              navigate(Constant.getPath("/affiliateoverview"));
            } else {
              navigate(Constant.getPath("/register"));
            }
          })}
          sx={{ backgroundColor: "red", borderRadius: "50px", padding: "10px 50px", color: "black", fontWeight: "bolder", marginTop: "25px" }}
        >{t("homepage.startearningtext")}
        </Button>
      </Box>
    );
  }

  const Banner = () => {
    return (
      <Box
        sx={{
          backgroundImage: `url(${(lang === 'bd' || lang === null)
            ? process.env.REACT_APP_HOME_BANNER_BD
            : process.env.REACT_APP_HOME_BANNER_EN
            })`,
          minHeight: {
            xs: "150px", sm: "750px"
          },

          backgroundSize: {
            xs: "contain",
            sm: "cover",
          },
          backgroundPosition: {
            xs: "top center",
            sm: "center",
          },
          backgroundRepeat: "no-repeat",
        }}
      >
      </Box>
    )
  }
  return (
    <Box pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      <Divider sx={{ backgroundColor: '#E51E37', width: '100%' }} />

      <Banner />

      <AffiliateAd />

      <StepsToEarn />

      <BestPartner />

      <OurProduct />

      <StartEarning />
    </Box>
  );
};

export default AffiliateHome;
