import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useTheme,
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  Grid,
  Container,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import FormTextField from "../components/form/FormTextField";
import Backdrop from "../components/common/Backdrop";
import { useLoginMutation } from "../features/api/authApiSlice";
import { setCredentials } from "../features/slices/authSlice";
import { selectCurrentToken } from "../features/slices/authSlice";
import { setAlert } from "../features/slices/alertSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import ImageCarousel from "../components/common/ImageCarousel";
import * as Constant from "../features/constant";
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const lang = localStorage.getItem("language");

  const bonusSliderImage_en = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_en.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_en.png",
  ];

  const bonusSliderImage_bd = [
    process.env.REACT_APP_BONUS_SLIDER + "slot_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "live_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "sport_welcome_bd.png",
    process.env.REACT_APP_BONUS_SLIDER + "/slot_daily_bd.png",
  ];

  const loginSchema = yup.object().shape({
    username: yup.string().required(t("validation.username")),
    password: yup.string().required(t("validation.password")),
  });

  // utilize the other hooks to display loader, display error message or anything
  const [login, { data, isLoading, isError, isSuccess, error, reset }] =
    useLoginMutation();

    const {
      control,
      handleSubmit,
      formState: { isSubmitting },
    } = useForm({
      resolver: yupResolver(loginSchema),
    });

  useEffect(() => {
    if (token) {
      navigate(Constant.getPath("/"));
    }
  }, []);

  const submitForm = async (data) => {
    let formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("grant_type", "password");

    const userData = await login(formData).unwrap();
    dispatch(setCredentials({ ...userData }));
  };

   useEffect(() => {
      if (isSubmitting && !isLoading && isSuccess && !token) {
        dispatch(
          setAlert({
            isOpen: true,
            message: t("header.loginsuccessmessage"),
            variant: "success",
          })
        );
        console.log("Successful login: Redirecting to affiliateoverview");
        navigate(Constant.getPath("/affiliateoverview"));
        setTimeout(() => {
          // For BD is not needed as homepage doesnt have promotion section
          // window.location.reload();
        }, 1000);
      }
    }, [isSubmitting, isLoading, isSuccess, token]);

  if (!isLoading && isError) {
    const errorMessage = error?.data?.error_description || t("header.loginfailmessage");

    dispatch(
      setAlert({
        isOpen: true,
        message: errorMessage,
        variant: "error",
      })
    );
    reset();
  }

  return (
    <Box backgroundColor="black" height="100vh">
      {/* <Box p={2} backgroundColor="black">
        <Grid container justifyContent="space-between">
          <Image
            width="178px"
            src={process.env.REACT_APP_LOGO}
            height="50px"
            onClick={null}
          />
          <IconButton
            onClick={() => {
              navigate("/");
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Box> */}
      <Container>
        <Box mt={12}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
            borderRadius={3}
          >
            <Box
              p={2}
              flexGrow={1}
              sx={{ cursor: "pointer", position: "relative" }}
              onClick={() => navigate(Constant.getPath("/login"))}
            >
              <Typography
                color={theme.palette.secondary.main}
                fontSize={14}
                fontWeight={800}
                align="center"
              >
                {t("mobileloginregister.login")}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderBottom: "2px solid",
                  width: "20%",
                  borderColor: theme.palette.secondary.main,
                }}
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              p={2}
              flexGrow={1}
              sx={{ cursor: "pointer", position: "relative" }}
              onClick={() => navigate(Constant.getPath("/register"))}
            >
              <Typography
                fontSize={14}
                fontWeight={800}
                align="center"
                color="white"
              >
                {t("mobileloginregister.signup")}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            {/* <ImageCarousel
              banners={lang === 'en' ? bonusSliderImage_en : bonusSliderImage_bd}
              navigation={false}
              spaceBetween={0}
            /> */}
            <Image
                width={mobileView?"80%":"60%"}
                sx={{ 
                  margin: "auto", 
                  display: "block", 
                }}
                src={process.env.REACT_APP_AFFILIATE_LOGO}
                onClick={null}
              />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ backgroundColor: theme.palette.background.paper }}
            justifyContent="center"
            pb={3}
            px={5}
          >
            <form onSubmit={handleSubmit(submitForm)}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            <PersonIcon sx={{ mt: 2, mr: 1 }} /> 
              <FormTextField
                  label={t("mobileloginregister.username")}
                  control={control}
                  controllerName="username"
                  size="small"
                  width="100%"
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyIcon sx={{ mt: 2, mr: 1 }} /> 
              <FormTextField
                  label={t("mobileloginregister.password")}
                  control={control}
                  controllerName="password"
                  type="password"
                  size="small"
                  width="100%"
              />
            </Box>

              <Box
                  display="flex"
                  justifyContent="center"
                  mt={isError ? "10px" : "30px"}
              >
                <Button variant="contained" type="submit">
                  {t("mobileloginregister.login")}
                </Button>
              </Box>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <a
                    style={{color: theme.palette.primary.main}}
                    href="/forgotPassword"
                >
                  <Typography mt={2} fontSize={14}>
                    {t("mobileloginregister.forgotpassword")}
                  </Typography>
                </a>
              </div>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                <Typography mt={2} fontSize={14} style={{ color: 'white' }}>
                  {t("Any issues? Please contact our ")}
                  <a
                      style={{ color: theme.palette.primary.main }}
                      href="/ContactUs"
                  >
                    customer service
                  </a>
                  {"."}
                </Typography>
              </div>

            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
