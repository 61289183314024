import { apiSlice } from "./apiSlice";

export const reportApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "WithdrawalReport",
    "TeamSizeReport"
  ],
  endpoints: (builder) => ({
    getWithdrawalReport: builder.query({
      query: ({ startdate, enddate }) => ({
        url: `/report/api/v1/withdrawReport?startdate=${startdate}&enddate=${enddate}`,
        method: "GET",
      }),
      providesTags: ["WithdrawalReport"],
    }),
    getAllDepositReport: builder.query({
      query: ({ startdate, enddate }) => {
         let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }

        return {
          url: `/report/api/v1/getalldeposit?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DepositReport"],
    }),
    getAllWithdrawReport: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }

        return {
          url: `/report/api/v1/getallwithdraw?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["WithdrawalReport"],
    }),
    getAllDownlineTurnover: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }

        return {
          url: `/report/api/v1/downlineturnover?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["TurnoverReport"],
    }),
    getMemberSummaryReport: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }

        return {
          url: `/report/api/v1/affiliatemembersummary?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["MemberSummaryReport"],
    }),
    getTeamSizeReport: builder.query({
      query: () => ({
        url: `/report/api/v1/teamSizeReport`,
        method: "GET",
      }),
      providesTags: ["TeamSizeReport"],
    }),

    getMonthlyCommission: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getMonthlyCommission?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["MonthlyCommission"],
    }),

    getDownlineIndividualSummary: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getDownlineIndividualSummary?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["DownlineIndividualSummary"],
    }),

    getAffiliateExpensesDetail: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/affiliateexpensesdetail?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateExpensesDetail"],
    }),

    getAffiliateProviderSummary: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAllAffiliateProviderSummary?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateProviderSummary"],
    }),

    getAffiliateOverviewDepoAndWithdraw: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAffiliateOverviewDepoAndWithdraw?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateOverviewDepoAndWithdraw"],
    }),

    getAffiliateOverviewCreditAndMember: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAffiliateOverviewCreditAndMember?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateOverviewCreditAndMember"],
    }),

    getDepositTotalCountReport: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getDepositTotalCountReport?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateDepositTotalCountReport"],
    }),

    getWithdrawalTotalCountReport: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getWithdrawalTotalCountReport?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateWithdrawalTotalCountReport"],
    }),

    getAffiliateDownlineRegisterData: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAffiliateDownlineRegisterData?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateDownlineRegisterData"],
    }),

    getAffiliateDownlineBetProvider: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAffiliateDownlineBetProvider?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateDownlineBetProvider"],
    }),

    getAffiliateDownlineRegisterData: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/getAffiliateDownlineRegisterData?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateDownlineRegisterData"],
    }),

    getAffiliateDownlineBonusDetail: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/findAffiliateBonusDetailReport?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateDownlineBonusDetail"],
    }),

    getAffiliateCommissionDetail: builder.query({
      query: ({ startdate, enddate }) => {
        let queryString = ""

        if (startdate) {
          const formattedStartDate = startdate.replace(/-/g, "/");
          queryString += `startDate=${formattedStartDate}`;
        }
        if (enddate) {
          const formattedEndDate = enddate.replace(/-/g, "/");
          queryString += (queryString ? "&" : "") + `endDate=${formattedEndDate}`;
        }  
        return {
          url: `/report/api/v1/findAffiliateCommissionDetail?${queryString}`,
          method: "GET",
        };
      },
      providesTags: ["AffiliateCommissionDetail"],
    }),

  })

  
});

export const {
  useLazyGetWithdrawalReportQuery,
  useGetAllDepositReportQuery,
  useGetAllWithdrawReportQuery,
  useGetAllDownlineTurnoverQuery,
  useGetTeamSizeReportQuery,
  useGetMemberSummaryReportQuery,
  useGetMonthlyCommissionQuery,
  useGetDownlineIndividualSummaryQuery,
  useGetAffiliateExpensesDetailQuery,
  useGetAffiliateProviderSummaryQuery,
  useGetAffiliateOverviewDepoAndWithdrawQuery,
  useGetAffiliateOverviewCreditAndMemberQuery,
  useGetDepositTotalCountReportQuery,
  useGetWithdrawalTotalCountReportQuery,
  useGetAffiliateDownlineRegisterDataQuery,
  useGetAffiliateDownlineBetProviderQuery,
  useAffiliateDownlineRegisterDataQuery,
  useGetAffiliateDownlineBonusDetailQuery,
  useGetAffiliateCommissionDetailQuery,
} = reportApiSlice;
