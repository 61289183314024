import React, { useState, useEffect } from 'react';
import "../components/Affiliate/style.css"
import {
  useGetShareLinkQuery,
  useGetProfileQuery,
} from "../features/api/userApiSlice";
import { useTranslation } from "react-i18next";
import { useGetAffiliateProviderSummaryQuery, useGetAffiliateOverviewDepoAndWithdrawQuery,useGetAffiliateOverviewCreditAndMemberQuery } from '../features/api/reportApiSlice';
import AffiliateCopiedTooltip from '../components/common/AffiliateCopiedTooltip ';
import { CompressOutlined, VerticalAlignBottom, VerticalAlignCenter } from '@mui/icons-material';
import { QRCodeCanvas } from 'qrcode.react';
import "../utils/en.json";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography
} from '@mui/material';

const Card = ({ title, data }) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      {data.map((item, index) => (
        <p key={index}>
          <strong>{item.label}:</strong> {item.value}
        </p>
      ))}
    </div>
  );
};


const AffiliateOverview = () => {

  const { t } = useTranslation();

  //fixed data for local testing, to be replaced with data from api call
  const quickSummaryData = [
    { label: "Number Of Clicks", value: "0" },
    { label: "New Sign up", value: "0" },
    { label: "First Deposit/Depositor", value: "BDT 0.00 / 0" },
    { label: "Active Member", value: "0" },
  ];

  const commissionData = [
    { label: "Total Member", value: "0" },
    { label: "Total Commission", value: "0.00" },
    { label: "Total Commission BDT", value: "0.00" },
  ];

  //format data from API 
  const formatData = (provider) => {
    return [
      { label: "Company W/L", value: `BDT ${provider.companyWinLoss?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}` },
      { label: "Total Active Member", value: ` ${provider.totalActiveMembers?.toLocaleString() || '0'}` },
      { label: "Number of Bet(s)", value: ` ${provider.betCount?.toLocaleString() || '0'}` }, 
      { label: "Turnover", value: `BDT ${provider.totalTurnover?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00'}` },
    ];
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [datesInitialized, setDatesInitialized] = useState(false);
  const [searchParams, setSearchParams] = useState({ startDate: '', endDate: '' });
  const [totalCompanyWinLoss, setTotalCompanyWinLoss] = useState(0);
  const [totalTurnover, setTotalTurnover] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalMember, setTotalMember] = useState(0);
  const [activeMember, setActiveMember] = useState(0);
  const [currentCredit, setCurrentCredit] = useState(0);
  const [open, setOpen] = useState(false);

  const getMostRecentMonday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); 
    const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; 
    const recentMonday = new Date(today);
    recentMonday.setDate(today.getDate() + mondayOffset);
    return recentMonday.toISOString().split('T')[0]; 
  };

  useEffect(() => {
    const monday = getMostRecentMonday();
    const today = new Date().toISOString().split('T')[0];
    setStartDate(monday);
    setEndDate(today);
    setSearchParams({ startDate: monday, endDate: today });

    setDatesInitialized(true);
  }, []);


  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const {
    data: providerSummary,
    isLoading: isProviderSummaryLoading,
    isError: isProviderSummaryError,
    isSuccess: isProviderSummarySuccess,
    error: providerSummaryError,
  } =  useGetAffiliateProviderSummaryQuery({
    startdate: searchParams.startDate,
    enddate: searchParams.endDate
  }, { skip: !datesInitialized });

  const {
    data: depoAndWithdraw,
    isLoading: isDepoAndWithdrawLoading,
    isError: isDepoAndWithdrawError,
    isSuccess: isDepoAndWithdrawSuccess,
    error: depoAndWithdrawError,
  } =  useGetAffiliateOverviewDepoAndWithdrawQuery({
    startdate: searchParams.startDate,
    enddate: searchParams.endDate
  }, { skip: !datesInitialized });

  const {
    data: creditAndMember,
    isLoading: iscreditAndMemberLoading,
    isError: iscreditAndMemberError,
    isSuccess: iscreditAndMemberSuccess,
    error: creditAndMemberError,
  } =  useGetAffiliateOverviewCreditAndMemberQuery({
    startdate: searchParams.startDate,
    enddate: searchParams.endDate
  }, { skip: !datesInitialized });

  // console.log(providerSummary);

  useEffect(() => {
    if (isProviderSummarySuccess && providerSummary?.data?.length > 0) {
      const { companyWinLossSum, totalTurnoverSum } = providerSummary.data.reduce(
        (acc, provider) => {
          acc.companyWinLossSum += provider.companyWinLoss || 0; // Use 0 if undefined
          acc.totalTurnoverSum += provider.totalTurnover || 0; // Use 0 if undefined
          return acc;
        },
        { companyWinLossSum: 0, totalTurnoverSum: 0 }
      );

      setTotalCompanyWinLoss(companyWinLossSum);
      setTotalTurnover(totalTurnoverSum);
      setTotalDeposit(depoAndWithdraw?.data.totalDeposit ?? 0); 
      setTotalWithdraw(depoAndWithdraw?.data.totalWithdraw ?? 0); 
      setTotalMember(creditAndMember?.data.totalMember ?? 0); 
      setActiveMember(creditAndMember?.data.activeMember ?? 0); 
      setCurrentCredit(creditAndMember?.data.currentCredit ?? 0); 

     // console.log(depoAndWithdraw);
      //console.log(companyWinLossSum);
      //console.log(totalTurnoverSum);
    } else {
      // Reset totals if no data or if loading/error state
      setTotalCompanyWinLoss(0);
      setTotalTurnover(0);
      setTotalDeposit(0);
      setTotalWithdraw(0);
      setTotalMember(0); 
      setActiveMember(0); 
      setCurrentCredit(0); 
    }
  }, [isProviderSummarySuccess, providerSummary,depoAndWithdraw]); // Dependency array includes success state and providerSummary

  if (isProviderSummaryLoading) {
    return <div>Loading...</div>; // Loading state
  }

  if (isProviderSummaryError) {
    return <div>Error: {providerSummaryError.message}</div>; // Error state
  }

  const summaryData = [
    { label: "Company W/L", value: `BDT ${totalCompanyWinLoss.toFixed(2)}` },
    { label: "Turnover", value: `BDT ${totalTurnover.toFixed(2)}` },
    { label: "Total Deposit", value: `BDT ${totalDeposit.toFixed(2)}` },
    { label: "Total Withdrawal", value: `BDT ${totalWithdraw.toFixed(2)}` },
  ];

  const summaryData2 = [
    { label: "Total Member", value: totalMember },
    { label: "Active Member", value: activeMember },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchClick = () => {
    setSearchParams({ startDate, endDate} );
  };

  return (
    <Box className='page-container' pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }} pb={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      <div className='link-stack-container'>
        {/* Affiliate Information */}
        <div className='link-container'>

          <div className='link-text'>
            <p><strong>{t("affiliateoverview.affinformation")} &nbsp; </strong></p>
            <p>{t("affiliateoverview.username")} {user?.data?.fullname}</p>
            <p>{t("affiliateoverview.currentcredit")} BDT {currentCredit}</p>
          </div>
        </div>
        
        {/* <div className='link-container'>
          <div className='link-text'>
            <p>{t("affiliateoverview.member")} &nbsp; </p>
            {shareLink?.result?.url ? (
              <a href={shareLink.result.url}>{shareLink.result.url}</a>
            ) : (
              <span>Loading...</span> 
            )}
          </div>
          <div className="buttons">
            <AffiliateCopiedTooltip displayText={t("affiliateoverview.share")} text={shareLink?.result?.url} />
            <button className="button button-qr" onClick={handleClickOpen}>{t("affiliateoverview.shareqr")} </button>
          </div>
        </div> */}
        <div className='link-container'>
          <div className='link-text'>
            <p>{t("affiliateoverview.registerurl")} &nbsp; </p>
            {shareLink?.result?.url ? (
              <a href={shareLink.result.url}>{shareLink.result.url}</a>
            ) : (
              <span>Loading...</span> 
            )}
          </div>
          <div className="buttons">
            <AffiliateCopiedTooltip displayText={t("affiliateoverview.share")} text={shareLink?.result?.url} />
            <button className="button button-qr" onClick={handleClickOpen} >{t("affiliateoverview.shareqr")}</button>
          </div>
        </div>
        {/* <div className='link-container'>
          <div className='link-text'>
            <p>{t("affiliateoverview.subaffiliate")} &nbsp; </p>
            {shareLink?.result?.url ? (
              <a href={shareLink.result.url}>{shareLink.result.url}</a>
            ) : (
              <span>Loading...</span> 
            )}
          </div>
          <div className="buttons">
            <AffiliateCopiedTooltip displayText={t("affiliateoverview.share")} text={shareLink?.result?.url} />
            <button className="button button-qr" onClick={handleClickOpen} >{t("affiliateoverview.shareqr")}</button>
          </div>

        </div> */}
        
        {/* Affiliate Summary */}
        <div className='link-text'>
          <p><strong>{t("affiliateoverview.overallsummary")} &nbsp; </strong></p>
        </div>
        <div className="card-container">
          <Card title={t("affiliateoverview.summary")} data={summaryData2} />
        </div>
        <div className='datePickerContainer'>
          <label htmlFor="from-date">{t("affiliateoverview.from")}</label>
          <input
            type="date"
            id="from-date"
            name="from-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label htmlFor="to-date">{t("affiliateoverview.to")}</label>
          <input
            type="date"
            id="to-date"
            name="to-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <button className='searchButton'
           style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%', maxWidth: '200px', margin: '0 auto' }}
          onClick={handleSearchClick}>{t("affiliateoverview.search")}</button>
        </div>
        <div className="card-container">
          <Card title={t("affiliateoverview.quicksummary")} data={quickSummaryData} />
          <Card title={t("affiliateoverview.commission")} data={commissionData} />
          <Card title={t("affiliateoverview.summary")} data={summaryData} />
          {providerSummary?.data?.length > 0 ? (
            providerSummary.data.map((provider) => (
              <Card
                key={provider.providerName}
                title={`{t("affiliateoverview.currentmonth")} ${provider.providerName}`}
                data={formatData(provider)}
              />
            ))
          ) : (
            <Card
              title={t("affiliateoverview.noproviderdata")}
              data={[]} // Or whatever structure you need for the card data
            />
          )}
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{color:'white'}}>{t("affiliateoverview.yourqr")}</DialogTitle>
        <DialogContent>
          <QRCodeCanvas value={shareLink?.result?.url} size={200} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {t("affiliateoverview.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AffiliateOverview;
